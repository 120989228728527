import styled from 'styled-components';
import { SmallTitle } from '../../ui/styled';

const StyledBannerSection = styled.div`
  background: rgb(255, 195, 47);
  background-image: url(/assets/warzywa-bg.jpg);
  background-size: cover;

  .titlePosition {
    padding: 100px 15px 100px 15px;

    h1 {
      padding: 15px 25px;
      border-radius: 10px;
    }
  }

  @media (min-width: 1000px) {
    display: grid;
    grid-template-columns: 1fr 1fr;

    img {
      margin-left: auto;
      height: 400px;
    }

    .titlePosition {
      padding: 70px 35px 35px;

      h1 {
        font-size: 40px;
      }

      padding: 40px;
      margin: auto;
      margin-left: 150px;
    }
  }
`;

export function BannerSection() {
  return (
    <StyledBannerSection>
      <div className="titlePosition">
        <SmallTitle className="bg-white">
          Kupując nasze produkty, pomagasz zmniejszyć ilość marnowanej żywności
          w Polsce
        </SmallTitle>
      </div>
      {/* <img src="/assets/home-mission-mobile.jpg" alt="Produce" /> */}
    </StyledBannerSection>
  );
}
