import styled from 'styled-components';

const StyledSingleCard = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 20px;
  padding-bottom: 35px;

  .text-content {
    grid-column: 2 / 6;

    .body-small {
      line-height: 26px;
    }

    h3 {
      margin-bottom: 16px;
    }

    p {
      font-weight: 400;
    }
  }

  @media (min-width: 1000px) {
    img {
      display: inline-block;
      margin-right: 25px;
      width: 45px;

      width: 80px;
    }

    h3 {
      font-size: 18px;
    }

    p {
      font-size: 18px;
    }
  }
`;

const SingleCard = ({ title, asset, bodyText }) => (
  <StyledSingleCard>
    <div>
      <img src={asset} alt="Save" />
    </div>
    <div className="text-content">
      <h3 className="body-small">
        <b>{title}</b>
      </h3>
      <p className="body-small">{bodyText}</p>
    </div>
  </StyledSingleCard>
);

const StyledDesktopGrid = styled.div`
  @media (min-width: 1000px) {
    display: grid;
    grid-template-columns: 1fr repeat(2, minmax(auto, 650px)) 1fr;
    .content-grid {
      grid-column: 2/4;
      padding-top: 30px;
    }
  }
`;

const StyledSellingPointsSection = styled.div`
  background-color: white;
  padding: 70px 35px 35px;
  margin-bottom: 20px;

  @media (min-width: 1000px) {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 40px;
  }
`;

export function SellingPointsSection() {
  return (
    <StyledDesktopGrid>
      <div className="content-grid">
        <StyledSellingPointsSection>
          <SingleCard
            asset="/assets/icon-1.webp"
            title="Naturalna żywność"
            bodyText="Dostarczamy jedynie świeże produkty doskonałej jakości. Takie produkty były wcześniej wyrzucane przez producentów ze względu na nieodpowiedni kształt lub rozmiar."
          />
          <SingleCard
            asset="/assets/icon-2.webp"
            title="Konkurencyjna cena"
            bodyText="Rolnicy chętnie sprzedają warzywa i owoce o mniej doskonałym wyglądzie w atrakcyjnych cenach. Dla klienta oznacza to okazję zakupu znacznie tańszych produktów."
          />
          <SingleCard
            asset="/assets/icon-3.webp"
            title="Mniej zmarnowanego jedzenia "
            bodyText="Nieznacznie obniżając nasze oczekiwania co do wyglądu bądź rozmiaru produktów możemy znacznie poszerzyć dostępny asortyment i uzyskać lepsze ceny. Warto pamiętać też o tym, że mniej idealne warzywa i owoce są przecież równie smaczne."
          />
        </StyledSellingPointsSection>
      </div>
    </StyledDesktopGrid>
  );
}
