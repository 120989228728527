import styled from 'styled-components';
import { DesktopGrid, SmallTitle } from '../../ui/styled';

const StyledTwoParagraphs = styled.div`
  margin-bottom: 45px;
  text-align: center;

  @media (min-width: 1000px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    text-align: left;
  }

  h3 {
    padding-top: 35px;
    font-size: 20px;
    line-height: 30px;
  }

  p {
    font-size: 16px;
    line-height: 26px;
    margin: 15px 0px 35px;
  }
`;

const TwoParagraphs = () => (
  <StyledTwoParagraphs>
    <div>
      <h3 className="body-medium">
        <b>“Brzydki” problem</b>
      </h3>
      <p className="body-small">
        Zdrowe jedzenie może być drogie. Jednocześnie nawet połowa uprawianych
        warzyw i owoców jest wyrzucana tylko ze względu na niedoskonały wygląd.
      </p>
    </div>
    <div>
      <h3 className="body-medium">
        <b>Nasze rozwiązanie</b>
      </h3>
      <p className="body-small">
        Bierzemy te mniej idealne warzywa i owoce, które wcześniej trafiłyby do
        kosza, i znajdujemy dla nich nowy dom. Twój dom.
      </p>
    </div>
  </StyledTwoParagraphs>
);

const ITEMS = [
  {
    title: 'Zmiany powierzchniowe',
    asset: '/assets/pomidor-z-oczami.jpg',
  },
  {
    title: 'Nietypowy kształt',
    asset: '/assets/ziemniak-serce.jpg',
  },
  {
    title: 'Zbyt duży rozmiar',
    asset: '/assets/podwojny-banan.jpg',
  },
  {
    title: 'Zbyt mały rozmiar',
    asset: '/assets/papryka.jpg',
  },
  // {
  //   title: 'Termin przydatności',
  //   asset: '/assets/home-diff-3@2x.png',
  // },
  // {
  //   title: 'Uszkodzone opakowanie',
  //   asset: '/assets/home-diff-4@2x.png',
  // },
];

const StyledEducationGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 15px;
  margin: 35px 0px 0px;

  @media (min-width: 1000px) {
    grid-template-columns: repeat(4, 3fr);
    gap: 20px;
  }

  div {
    text-align: center;
  }

  img {
    width: 145px;
    margin: auto;
    border-radius: 25px;
  }

  p {
    text-align: center;
    margin: 0px;
    padding: 10px 0px;
    font-size: 14px;
    line-height: 24px;
    font-weight: bolder;
  }
`;

const EducationGrid = () => {
  return (
    <StyledEducationGrid>
      {ITEMS.map((item, index) => (
        <div key={index} className="mx-auto">
          <img src={item.asset} />
          <p>{item.title}</p>
        </div>
      ))}
    </StyledEducationGrid>
  );
};

const StyledEducation = styled.div`
  padding: 35px;

  @media (min-width: 1000px) {
    padding-top: 70px;
    padding-bottom: 80px;
  }
`;

export function Education() {
  return (
    <StyledEducation>
      <DesktopGrid isTranspartentBg={true}>
        <TwoParagraphs />
        <SmallTitle style={{ textAlign: 'center' }}>
          Co wyróżnia nasze produkty?
        </SmallTitle>
        <EducationGrid />
      </DesktopGrid>
    </StyledEducation>
  );
}
