import { Button } from 'shared/ui/Button';
import styled from 'styled-components';
import { Title } from '../../ui/styled';

const StyledHeroSection = styled.div`
  background-color: #eee7ca;
  position: relative;
  background-image: url(/assets/home-header-desktop-2x-m.jpg);
  background-size: cover;
  height: 100vh;

  .hero-body {
    padding: 50px 35px 35px;

    .body-small {
      margin-bottom: 35px;
      .red-text {
        color: #9a4726;
      }
    }
  }

  .hero-image-desktop {
    display: none;
  }

  @media (min-width: 1000px) {
    // height: 600px;
    background-image: url(/assets/home-header-desktop-2x.jpg);
    height: auto;

    .hero-image-mobile {
      display: none;
    }

    .hero-image-desktop {
      display: block;
      position: absolute;
      height: 600px;
      right: 0px;
    }

    h1 {
      font-size: 40px;
      line-height: 70px;
    }

    .desktop-grid {
      display: grid;
      grid-template-columns: 1fr repeat(2, minmax(auto, 650px)) 1fr;

      background-size: cover;

      .content-grid {
        grid-column: 2/4;
        padding-top: 80px;
        display: grid;
        grid-template-columns: 1fr 1fr;

        .hero-body {
          z-index: 1;
          padding: 40px;
          grid-column: 1/2;
          max-width: 520px;

          p {
            font-size: 20px;
            line-height: 32px;
          }
        }
      }
    }
  }
`;

export const HeroSection = () => (
  <StyledHeroSection>
    {/* <img src="/assets/hero-mobile.jpg" className="hero-image-mobile" />
    <img
      src="/assets/home-header-desktop-2x.jpg"
      className="hero-image-desktop"
    /> */}
    <div className="desktop-grid">
      <div className="content-grid">
        <div className="hero-body">
          <Title>Niedoskonały wygląd. Doskonały smak.</Title>
          <p className="body-small">
            Kupuj pełnowartościowe, spełniające wszelkie wymagania jakościowe
            produkty, których wygląd może nieco odbiegać od ideału.
            {/* Wygląd nie ma znaczenia. Nasze warzywa spełniają wszystkie wymagania jakościowe, zachowując doskonały smak.  */}
            {/* <b className="red-text">up to 40% off</b> */}
          </p>
          <Button href="/choose">Wybierz swój box</Button>
        </div>
      </div>
    </div>
  </StyledHeroSection>
);
