import { Header } from '../../Header';
import { DefaultFooter } from '../../ui/DefaultFooter';
import { BannerSection } from './BannerSection';
import { Education } from './Education';
import { HeroSection } from './HeroSection';
import { SellingPointsSection } from './SellingPointsSection';

export function LandingPage() {
  return (
    <>
      <Header />
      <HeroSection />
      <SellingPointsSection />
      {/* TODO enable later */}
      {/* <DesktopGrid>
        <ItemsDisplay />
      </DesktopGrid> */}
      <BannerSection />
      {/* <DesktopGrid> */}
      <Education />
      {/* </DesktopGrid> */}
      <DefaultFooter />
    </>
  );
}
