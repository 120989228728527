import Head from 'next/head';
import { LandingPage } from '../components/static/LandingPage/LandingPage';

export default function Page() {
  return (
    <>
      <Head>
        <title>Wojna Warzyw - Niedoskonały wygląd. Doskonały smak.</title>
        <meta
          name="description"
          content="Wojna Warzyw dostarcza pudełka niedoskonałych owoców i warzyw. Pomóż w walce z marnowaniem jedzenia w Polsce."
        />
      </Head>
      <LandingPage />
    </>
  );
}
