import styled from 'styled-components';

export const Title = styled.h1`
  font-size: 36px;
  line-height: 46px;
  margin-top: 0px;
  margin-bottom: 35px;
  font-weight: bolder;
`;

export const SmallTitle = styled.h1`
  font-size: 30px;
  margin-top: 0px;
  margin-bottom: 35px;
  font-weight: bolder;
`;

export const OnlyMobile = styled.div`
  @media (min-width: 1000px) {
    display: none;
  }
`;
export const OnlyDesktop = styled.div`
  @media (max-width: 1000px) {
    display: none;
  }
`;

const StyledDesktopGrid = styled.div`
  background-color: red;
  @media (min-width: 1000px) {
    display: grid;
    grid-template-columns: 1fr repeat(2, minmax(auto, 650px)) 1fr;
    .content-grid {
      grid-column: 2/4;
    }
  }
`;

const StyledDesktopGridWhite = styled.div`
  background-color: white;
  @media (min-width: 1000px) {
    display: grid;
    grid-template-columns: 1fr repeat(2, minmax(auto, 650px)) 1fr;
    .content-grid {
      grid-column: 2/4;
    }
  }
`;
const StyledDesktopGridTransparent = styled.div`
  @media (min-width: 1000px) {
    display: grid;
    grid-template-columns: 1fr repeat(2, minmax(auto, 650px)) 1fr;
    .content-grid {
      grid-column: 2/4;
    }
  }
`;

export function DesktopGrid({ children, isWhiteBg, isTranspartentBg }) {
  if (isTranspartentBg) {
    return (
      <StyledDesktopGridTransparent>
        <div className="content-grid">{children}</div>
      </StyledDesktopGridTransparent>
    );
  }

  return isWhiteBg ? (
    <StyledDesktopGridWhite>
      <div className="content-grid">{children}</div>
    </StyledDesktopGridWhite>
  ) : (
    <StyledDesktopGrid>
      <div className="content-grid">{children}</div>
    </StyledDesktopGrid>
  );
}
